import * as React from "react"
import Link from "gatsby-link"
import { navigate } from "gatsby"
const angleIcon = require("../images/icons/dp-icon-arrow-link.svg")
const clockIcon = require("../images/icons/dp-clock.svg")

const Card = props => {
  const clickHandler = e => {
    if (
      !(e.target instanceof HTMLLinkElement) &&
      !(e.target instanceof HTMLAnchorElement)
    ) {
      navigate(props.to)
    }
  }

  return (
    <div className="c-card c-card--white c-card--simple">
      <div className="c-card__content">
        <h2 className="c-card__title">
          <Link to={props.to} activeClassName="-active">
            {props.title}
            <img className="c-card__link-arrow" src={angleIcon} alt="" />
          </Link>
        </h2>
        <p>{props.text}</p>
        <div className="c-card__footer">
          <time className="c-card__time" dateTime={props.datetime}>
            {props.estimate}
          </time>
          <img className="c-card__clock" src={clockIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Card
