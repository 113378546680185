import * as React from "react"
import { Helmet } from "react-helmet"

import Hero from "../../components/hero"
import PageHeader from "../../components/page-header"
import Card from "../../components/card"
import Footer from "../../components/footer"

import "../../scss/site.scss"
//import StickyCta from "../../components/sticky-cta"

const ThemePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Om Demokrati | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/om-demokrati"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/om-demokrati"
        ></meta>
        <meta property="og:title" content="Om Demokrati"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--om-demokrati"
        >
          <Hero title="Om demokrati">
            <p>
              Demokratin är fantastisk men komplicerad. Den tar inga raka vägar
              och blir nog aldrig riktigt färdig. Och det är du och alla
              tillsammans som kan göra demokratin bättre och starkare.
            </p>
            <p>
              Vilka är de demokratiska principerna? Varför ska vi värna
              demokratin?
            </p>
          </Hero>
          <section className="c-card-grid">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="demokratiska-principer"
                    title="Demokratiska principer"
                    text="Det finns ingen beskrivning av vad demokrati är som alla håller med om. Inte ens experter och forskare är överens. Men det finns ändå saker som nästan alla är överens om är viktiga i demokratin. Vi kan kalla dem Demokratiska principer eller byggstenar för demokratin."
                    datetime="PT30M"
                    estimate="Övning, ca 30 min"
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="roster-om-demokrati"
                    title="Röster om demokrati"
                    text="Tre personer som själva upplevt diktatur pratar om demokratins främsta värden. Korta intervjuer om varför demokrati behövs, vad den egentligen betyder och hur vi gemensamt kan förbättra demokratin.
Intervjuerna innehåller svåra ord som kan behöva förklaras för eleverna."
                    datetime="PT40M"
                    estimate="Övning, ca 30-60 min"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default ThemePage
